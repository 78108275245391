import React, { useState, useEffect, ChangeEvent } from "react"
import { graphql, navigate } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import SearchInput from "../components/SearchInput"
import ProjectCard from "../components/ProjectCard"
import ProjectSelector from "../components/ProjectSelector"

const SoftwarePage = ({ data, title = "Software" }) => {
  const [projectSearchInput, setProjectSearchInput] = useState("")
  const [selectedProjectData, setSelectedProjectData] = useState(
    data.allStothartProjectsJson.nodes
  )
  const [selectedProjectCount, setSelectedProjectCount] = useState(0)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProjectSearchInput(event.target.value)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.search) {
        try {
          let query = window.location.search.split("=")[1]
          query = decodeURI(query)
          setProjectSearchInput(query)
        } catch {}
      }
    }
  }, [])

  useEffect(() => {
    const projectSearchInputArray = projectSearchInput
      .toLowerCase()
      .trim()
      .split(" ")

    const newSelectedProjectData = data.allStothartProjectsJson.nodes.filter(
      project => {
        let inputWordsFound = 0
        for (const inputWord of projectSearchInputArray) {
          let inputWordFound = false
          for (const property in project) {
            if (project[property] && property !== "url") {
              const propertyValue = project[property].toString().toLowerCase()
              if (propertyValue.includes(inputWord)) {
                inputWordFound = true
              }
            }
          }
          if (inputWordFound) inputWordsFound += 1
        }
        return inputWordsFound === projectSearchInputArray.length ? true : false
      }
    )

    setSelectedProjectData(newSelectedProjectData)
    setSelectedProjectCount(newSelectedProjectData.length)

    if (projectSearchInput.trim().length > 0) {
      navigate(`?q=${projectSearchInput}`)
    } else {
      navigate("/software")
    }
  }, [projectSearchInput])

  const projectCards = selectedProjectData.map(project => {
    const image = getImage(project.image)

    const technologies = project.technologies.map(technologyName => {
      const technologyObject = data.allTechnologiesJson.nodes.filter(
        technology => {
          return technology.name.toLowerCase().trim() ===
            technologyName.toLowerCase().trim()
            ? true
            : false
        }
      )
      const technologyIcon = getImage(technologyObject[0].image)
      return { name: technologyName, image: technologyIcon }
    })

    return (
      <ProjectCard
        key={project.id}
        title={project.title}
        description={project.description}
        image={image}
        technologies={technologies}
        url={project.url ? project.url : null}
        year={project.year}
      />
    )
  })

  return (
    <>
      <div className="pt-6 pb-6">
        <SearchInput
          label="Search Software"
          handleInputChange={handleInputChange}
          searchInput={projectSearchInput}
          searchResultCount={selectedProjectCount}
        />
      </div>

      <div className="mt-3 grid gap-3 grid-cols-3 md:flex md:flex-wrap">
        {data.allTechnologiesJson.nodes.map(technology => {
          const image = getImage(technology.image)
          const isOn = projectSearchInput
            .toLowerCase()
            .includes(technology.name.toLowerCase())
            ? true
            : false

          return (
            <ProjectSelector
              key={technology.name}
              selectorString={technology.name}
              icon={image}
              on={isOn}
              onClick={() => {
                if (!isOn) {
                  setProjectSearchInput(
                    oldInput => `${oldInput} ${technology.name.toLowerCase()}`
                  )
                  navigate(`?q=${technology.name.toLowerCase()}`)
                } else {
                  setProjectSearchInput(oldInput => {
                    let newInput = oldInput
                      .toLowerCase()
                      .replaceAll(technology.name.toLowerCase(), "")
                      .replace(/\s{2,}/g, " ")
                      .trim()
                    return newInput
                  })
                }
              }}
            />
          )
        })}
      </div>

      <div className="pt-9 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
        {selectedProjectCount > 0 ? projectCards : <p>No software found.</p>}
      </div>
    </>
  )
}

export const query = graphql`
  {
    allStothartProjectsJson(sort: { fields: year, order: DESC }) {
      nodes {
        id
        title
        description
        url
        technologies
        year
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    allTechnologiesJson {
      nodes {
        name
        image {
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 16, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default SoftwarePage
