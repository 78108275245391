import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

type ProjectSelectorType = {
  selectorString: string
  onClick: () => void
  on: boolean
  extraStyle?: string
  icon: IGatsbyImageData
}

const ProjectSelector = ({
  selectorString,
  onClick,
  on,
  extraStyle,
  icon,
}: ProjectSelectorType) => {
  const statusStyles = {
    on: `bg-gray-400`,
    off: `md:hover:bg-gray-100`,
  }
  const statusStyle = on ? statusStyles.on : statusStyles.off

  return (
    <button
      onClick={onClick}
      className={`focus:outline-none select-none rounded-lg shadow-sm border border-gray-200 bg-white ${statusStyle} ${extraStyle}`}
    >
      <div className="flex space-x-2 m-2">
        <GatsbyImage image={icon} alt="" />
        <div className="text-xs font-semibold">{selectorString}</div>
      </div>
    </button>
  )
}

export default ProjectSelector
