import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import CardExternalLink from "./CardExternalLink"

const ProjectCard = ({
  image,
  title,
  description,
  technologies,
  url,
  year,
}) => {
  return (
    <article className="flex flex-col card p-3 space-y-3">
      <div className="flex flex-col space-y-3">
          <div className="font-bold not-italic capitalize leading-tight">
            {title} ({year})
          </div>
        <GatsbyImage image={image} alt={title} />
        <div className="text-sm text-gray-700">{description}</div>
      </div>

      <div className="flex flex-col flex-grow justify-end">
        <div className="flex space-x-4 items-center place-content-between w-full">
          <div className="flex flex-wrap">
            {technologies.map(technology => (
              <GatsbyImage
                key={technology.name}
                image={technology.image}
                alt={technology.name}
                className="mr-1"
              />
            ))}
          </div>
          {url ? <CardExternalLink url={url} label="Software Page" /> : null}
        </div>
      </div>
    </article>
  )
}

export default ProjectCard
